import { User } from '../api/auth';
import { AccountInitialDto, CollegeCoaches } from '../api/account';
import { CollegeTagsDto } from '../api/tags';
import { TournamentShortListDto } from '../api/tournaments';
import { AccountInitialSportsDto } from '../api/account'
import { AthleteListQueryDto, AthleteFilterDto } from '../api/athletes';
import { CollegeGroupDto, AthleteRankForFiltersList } from '../api/ranks';
import { OrderType } from '../component/blocks/athletes/Athlete.types';
import { CollegeTasksDto } from '../api/tasks';
import { ClubFilterDto, ClubListQueryDto } from '../api/clubs';

export interface AppState {
  isUserLogged: boolean;
  user?: User;
  account?: AccountInitialDto;
  activeSport?: AccountInitialSportsDto;
  collegeTags: CollegeTagsDto[];
  deletedTags: CollegeTagsDto[];
  systemTags: CollegeTagsDto[];
  activeTournament?: number;
  tournaments?: TournamentShortListDto;
  collegeGroups: CollegeGroupDto[];
  isStateInitialized: boolean;
  collegeCoaches?: CollegeCoaches[];
  activeTagsTab: number;
  athleteListQuery: AthleteListQueryDto;
  clubListQuery: ClubListQueryDto;
  // athleteList: AthletesListDto;
  athleteFilter: AthleteFilterDto;
  clubFilter: ClubFilterDto;
  ranksForFilter?: AthleteRankForFiltersList[];
  tasks: CollegeTasksDto[];
  isVisibleRanks?: boolean;
  isVisibleTasks?: boolean;
}

export const initialState: AppState = {
  isUserLogged: false,
  isStateInitialized: false,
  activeTagsTab: 0,
  isVisibleRanks: false,
  collegeTags: [],
  deletedTags: [],
  systemTags: [],
  collegeGroups: [],
  // athleteList: { athletes: [], total: 0 },
  athleteListQuery: {
    page: 1, page_length: 25, sort_by: 'last', sort_order: 'ASC' as OrderType,
  },
  clubListQuery: {
    page: 1, page_length: 25, sort_by: 'club_name', sort_order: 'ASC' as OrderType,
  },
  tasks: [],
  athleteFilter: {
    first: '',
    last: '',
    gradyears: [],
    positions: [],
    states: [],
    club: '',
    is_evaluated: false,
    tags: [],
    tasks: [],
    is_event: false,
    tournament: null,
  },
  clubFilter: {
    club_master_id: null,
    club_name: null,
    club_region: [],
    club_state: [],
    tournament: null,
  }
};
