import { Task } from '../Athlete.types';

export const ungroupedSports: any[] = [
  {
    'sport_position_id': 3,
    'sports_id': 2,
    'label': 'Middle',
    'abbrev': 'MB',
  },
  {
    'sport_position_id': 4,
    'sports_id': 2,
    'label': 'Right Side',
    'abbrev': 'RS',
  },
  {
    'sport_position_id': 5,
    'sports_id': 2,
    'label': 'Outside',
    'abbrev': 'OH',
  },
  {
    'sport_position_id': 6,
    'sports_id': 2,
    'label': 'Setter',
    'abbrev': 'S',
  },
  {
    'sport_position_id': 7,
    'sports_id': 2,
    'label': 'Libero',
    'abbrev': 'LB',
  },
  {
    'sport_position_id': 8,
    'sports_id': 2,
    'label': 'Defensive Specialist',
    'abbrev': 'DS',
  },
  {
    'sport_position_id': 9,
    'sports_id': 3,
    'label': 'Middle',
    'abbrev': 'MB',
  },
  {
    'sport_position_id': 10,
    'sports_id': 3,
    'label': 'Right Side',
    'abbrev': 'RS',
  },
  {
    'sport_position_id': 11,
    'sports_id': 3,
    'label': 'Outside',
    'abbrev': 'OH',
  },
  {
    'sport_position_id': 12,
    'sports_id': 3,
    'label': 'Setter',
    'abbrev': 'S',
  },
  {
    'sport_position_id': 13,
    'sports_id': 3,
    'label': 'Libero',
    'abbrev': 'LB',
  },
  {
    'sport_position_id': 14,
    'sports_id': 3,
    'label': 'Defensive Specialist',
    'abbrev': 'DS',
  },
  {
    'sport_position_id': 15,
    'sports_id': 6,
    'label': 'Pitcher',
    'abbrev': 'P',
  },
  {
    'sport_position_id': 16,
    'sports_id': 6,
    'label': 'Catcher',
    'abbrev': 'C',
  },
  {
    'sport_position_id': 17,
    'sports_id': 6,
    'label': 'First Base',
    'abbrev': '1B',
  },
  {
    'sport_position_id': 18,
    'sports_id': 6,
    'label': 'Second Base',
    'abbrev': '2B',
  },
  {
    'sport_position_id': 19,
    'sports_id': 6,
    'label': 'Third Base',
    'abbrev': '3B',
  },
  {
    'sport_position_id': 20,
    'sports_id': 6,
    'label': 'Shortstop',
    'abbrev': 'SS',
  },
  {
    'sport_position_id': 21,
    'sports_id': 6,
    'label': 'Outfield',
    'abbrev': 'OF',
  },
  {
    'sport_position_id': 22,
    'sports_id': 6,
    'label': 'Utility',
    'abbrev': 'UT',
  },
  {
    'sport_position_id': 23,
    'sports_id': 8,
    'label': 'Forward',
    'abbrev': 'FW',
  },
  {
    'sport_position_id': 24,
    'sports_id': 8,
    'label': 'Midfield',
    'abbrev': 'MD',
  },
  {
    'sport_position_id': 25,
    'sports_id': 8,
    'label': 'Back',
    'abbrev': 'BK',
  },
  {
    'sport_position_id': 26,
    'sports_id': 8,
    'label': 'Goalkeeper',
    'abbrev': 'GK',
  },
  {
    'sport_position_id': 27,
    'sports_id': 9,
    'label': 'Forward',
    'abbrev': 'FW',
  },
  {
    'sport_position_id': 28,
    'sports_id': 9,
    'label': 'Midfield',
    'abbrev': 'MD',
  },
  {
    'sport_position_id': 29,
    'sports_id': 9,
    'label': 'Back',
    'abbrev': 'BK',
  },
  {
    'sport_position_id': 30,
    'sports_id': 9,
    'label': 'Goalkeeper',
    'abbrev': 'GK',
  },
  {
    'sport_position_id': 31,
    'sports_id': 6,
    'label': 'Infield',
    'abbrev': 'IF',
  },
  {
    'sport_position_id': 32,
    'sports_id': 5,
    'label': 'Point Guard',
    'abbrev': 'PG',
  },
  {
    'sport_position_id': 33,
    'sports_id': 5,
    'label': 'Shooting Guard',
    'abbrev': 'SG',
  },
  {
    'sport_position_id': 34,
    'sports_id': 5,
    'label': 'Small Forward',
    'abbrev': 'SF',
  },
  {
    'sport_position_id': 35,
    'sports_id': 5,
    'label': 'Power Forward',
    'abbrev': 'PF',
  },
  {
    'sport_position_id': 36,
    'sports_id': 5,
    'label': 'Center',
    'abbrev': 'C',
  },
  {
    'sport_position_id': 37,
    'sports_id': 4,
    'label': 'Post',
    'abbrev': 'P',
  },
  {
    'sport_position_id': 38,
    'sports_id': 4,
    'label': 'Wing',
    'abbrev': 'W',
  },
  {
    'sport_position_id': 39,
    'sports_id': 4,
    'label': 'Point Guard',
    'abbrev': 'PG',
  },
  {
    'sport_position_id': 40,
    'sports_id': 4,
    'label': 'Shooting Guard',
    'abbrev': 'SG',
  },
  {
    'sport_position_id': 41,
    'sports_id': 4,
    'label': 'Small Forward',
    'abbrev': 'SF',
  },
  {
    'sport_position_id': 42,
    'sports_id': 4,
    'label': 'Power Forward',
    'abbrev': 'PF',
  },
  {
    'sport_position_id': 43,
    'sports_id': 4,
    'label': 'Center',
    'abbrev': 'C',
  },
  {
    'sport_position_id': 44,
    'sports_id': 22,
    'label': '1st Base',
    'abbrev': '1B',
  },
  {
    'sport_position_id': 45,
    'sports_id': 22,
    'label': '2nd Base',
    'abbrev': '2B',
  },
  {
    'sport_position_id': 46,
    'sports_id': 22,
    'label': '3rd Base',
    'abbrev': '3B',
  },
  {
    'sport_position_id': 47,
    'sports_id': 22,
    'label': 'Catcher',
    'abbrev': 'C',
  },
  {
    'sport_position_id': 48,
    'sports_id': 22,
    'label': 'Center Fielder',
    'abbrev': 'CF',
  },
  {
    'sport_position_id': 49,
    'sports_id': 22,
    'label': 'Left Fielder',
    'abbrev': 'LF',
  },
  {
    'sport_position_id': 50,
    'sports_id': 22,
    'label': 'Left Handed Hitter',
    'abbrev': 'LHH',
  },
  {
    'sport_position_id': 51,
    'sports_id': 22,
    'label': 'LHP',
    'abbrev': 'LHP',
  },
  {
    'sport_position_id': 52,
    'sports_id': 22,
    'label': 'Right Fielder',
    'abbrev': 'RF',
  },
  {
    'sport_position_id': 53,
    'sports_id': 22,
    'label': 'Right Handed Hitter',
    'abbrev': 'RHH',
  },
  {
    'sport_position_id': 54,
    'sports_id': 22,
    'label': 'RHP',
    'abbrev': 'RHP',
  },
  {
    'sport_position_id': 55,
    'sports_id': 22,
    'label': 'Switch Hitter',
    'abbrev': 'SH',
  },
  {
    'sport_position_id': 56,
    'sports_id': 22,
    'label': 'Shortstop',
    'abbrev': 'SS',
  },
  {
    'sport_position_id': 57,
    'sports_id': 8,
    'label': 'Defenders',
    'abbrev': 'D',
  },
  {
    'sport_position_id': 58,
    'sports_id': 8,
    'label': 'Forwards',
    'abbrev': 'F',
  },
  {
    'sport_position_id': 59,
    'sports_id': 8,
    'label': 'Midfielders',
    'abbrev': 'MF',
  },
  {
    'sport_position_id': 60,
    'sports_id': 23,
    'label': 'Athlete',
    'abbrev': 'Ath',
  },
  {
    'sport_position_id': 61,
    'sports_id': 23,
    'label': 'Center',
    'abbrev': 'C',
  },
  {
    'sport_position_id': 62,
    'sports_id': 23,
    'label': 'Cornerback',
    'abbrev': 'CB',
  },
  {
    'sport_position_id': 63,
    'sports_id': 23,
    'label': 'Defensive End',
    'abbrev': 'DE',
  },
  {
    'sport_position_id': 64,
    'sports_id': 23,
    'label': 'Defensive Tackle',
    'abbrev': 'DT',
  },
  {
    'sport_position_id': 65,
    'sports_id': 23,
    'label': 'Fullback',
    'abbrev': 'FB',
  },
  {
    'sport_position_id': 66,
    'sports_id': 23,
    'label': 'Kicker',
    'abbrev': 'K',
  },
  {
    'sport_position_id': 67,
    'sports_id': 23,
    'label': 'Long Snapper',
    'abbrev': 'LS',
  },
  {
    'sport_position_id': 68,
    'sports_id': 23,
    'label': 'Middle Linebacker',
    'abbrev': 'MLB',
  },
  {
    'sport_position_id': 69,
    'sports_id': 23,
    'label': 'Offensive Guard',
    'abbrev': 'OG',
  },
  {
    'sport_position_id': 70,
    'sports_id': 23,
    'label': 'Outside Linebacker',
    'abbrev': 'OLB',
  },
  {
    'sport_position_id': 71,
    'sports_id': 23,
    'label': 'Offensive Tackle',
    'abbrev': 'OT',
  },
  {
    'sport_position_id': 72,
    'sports_id': 23,
    'label': 'Punter',
    'abbrev': 'P',
  },
  {
    'sport_position_id': 73,
    'sports_id': 23,
    'label': 'Quarterback',
    'abbrev': 'QB',
  },
  {
    'sport_position_id': 74,
    'sports_id': 23,
    'label': 'Running Back',
    'abbrev': 'RB',
  },
  {
    'sport_position_id': 75,
    'sports_id': 23,
    'label': 'Safety',
    'abbrev': 'S',
  },
  {
    'sport_position_id': 76,
    'sports_id': 23,
    'label': 'Tight End',
    'abbrev': 'TE',
  },
  {
    'sport_position_id': 77,
    'sports_id': 23,
    'label': 'Wide Receiver',
    'abbrev': 'WR',
  },
  {
    'sport_position_id': 78,
    'sports_id': 11,
    'label': 'Center',
    'abbrev': 'C',
  },
  {
    'sport_position_id': 79,
    'sports_id': 11,
    'label': 'Goalie',
    'abbrev': 'G',
  },
  {
    'sport_position_id': 80,
    'sports_id': 11,
    'label': 'Left Defenseman',
    'abbrev': 'LD',
  },
  {
    'sport_position_id': 81,
    'sports_id': 11,
    'label': 'Left Wing',
    'abbrev': 'LW',
  },
  {
    'sport_position_id': 82,
    'sports_id': 11,
    'label': 'Right Defenseman',
    'abbrev': 'RD',
  },
  {
    'sport_position_id': 83,
    'sports_id': 11,
    'label': 'Right Wing',
    'abbrev': 'RW',
  },
  {
    'sport_position_id': 84,
    'sports_id': 13,
    'label': 'Attack',
    'abbrev': 'Attac',
  },
  {
    'sport_position_id': 85,
    'sports_id': 13,
    'label': 'Defense',
    'abbrev': 'Defen',
  },
  {
    'sport_position_id': 86,
    'sports_id': 13,
    'label': 'Faceoff',
    'abbrev': 'Faceo',
  },
  {
    'sport_position_id': 87,
    'sports_id': 13,
    'label': 'Goalie',
    'abbrev': 'Goali',
  },
  {
    'sport_position_id': 88,
    'sports_id': 13,
    'label': 'Long Stick Midfielders',
    'abbrev': 'Longs',
  },
  {
    'sport_position_id': 89,
    'sports_id': 15,
    'label': 'Attacking Midfielder',
    'abbrev': 'AM',
  },
  {
    'sport_position_id': 90,
    'sports_id': 15,
    'label': 'Center Back',
    'abbrev': 'CB',
  },
  {
    'sport_position_id': 91,
    'sports_id': 15,
    'label': 'Defensive Midfielder',
    'abbrev': 'Def M',
  },
  {
    'sport_position_id': 92,
    'sports_id': 15,
    'label': 'Forward',
    'abbrev': 'FW',
  },
  {
    'sport_position_id': 93,
    'sports_id': 15,
    'label': 'Goalkeeper',
    'abbrev': 'GK',
  },
  {
    'sport_position_id': 94,
    'sports_id': 15,
    'label': 'Outside Back',
    'abbrev': 'OB',
  },
  {
    'sport_position_id': 95,
    'sports_id': 15,
    'label': 'Outside Midfielder',
    'abbrev': 'OM',
  },
  {
    'sport_position_id': 96,
    'sports_id': 17,
    'label': 'Doubles',
    'abbrev': 'Doubl',
  },
  {
    'sport_position_id': 97,
    'sports_id': 17,
    'label': 'Singles',
    'abbrev': 'Singl',
  },
  {
    'sport_position_id': 98,
    'sports_id': 3,
    'label': 'Middle Hitter',
    'abbrev': 'MH',
  },
  {
    'sport_position_id': 99,
    'sports_id': 3,
    'label': 'Opposite',
    'abbrev': 'OPP',
  },
  {
    'sport_position_id': 100,
    'sports_id': 19,
    'label': '2M',
    'abbrev': '2M',
  },
  {
    'sport_position_id': 101,
    'sports_id': 19,
    'label': '2M Defender',
    'abbrev': '2M De',
  },
  {
    'sport_position_id': 102,
    'sports_id': 19,
    'label': 'Driver',
    'abbrev': 'Drive',
  },
  {
    'sport_position_id': 103,
    'sports_id': 19,
    'label': 'Flat',
    'abbrev': 'Flat',
  },
  {
    'sport_position_id': 104,
    'sports_id': 19,
    'label': 'Goalie',
    'abbrev': 'Goali',
  },
  {
    'sport_position_id': 105,
    'sports_id': 19,
    'label': 'Hole',
    'abbrev': 'Hole',
  },
  {
    'sport_position_id': 106,
    'sports_id': 19,
    'label': 'Point Guard',
    'abbrev': 'Point',
  },
  {
    'sport_position_id': 107,
    'sports_id': 19,
    'label': 'Utility',
    'abbrev': 'Utili',
  },
  {
    'sport_position_id': 108,
    'sports_id': 19,
    'label': 'Wing',
    'abbrev': 'Wing',
  },
  {
    'sport_position_id': 109,
    'sports_id': 6,
    'label': 'Center Fielder',
    'abbrev': 'CF',
  },
  {
    'sport_position_id': 110,
    'sports_id': 6,
    'label': 'Designated Hitter',
    'abbrev': 'DH',
  },
  {
    'sport_position_id': 111,
    'sports_id': 6,
    'label': 'Infielder',
    'abbrev': 'INF',
  },
  {
    'sport_position_id': 112,
    'sports_id': 6,
    'label': 'Left Fielder',
    'abbrev': 'LF',
  },
  {
    'sport_position_id': 113,
    'sports_id': 6,
    'label': 'LHP',
    'abbrev': 'LHP',
  },
  {
    'sport_position_id': 114,
    'sports_id': 6,
    'label': 'Right Fielder',
    'abbrev': 'RF',
  },
  {
    'sport_position_id': 115,
    'sports_id': 6,
    'label': 'RHP',
    'abbrev': 'RHP',
  },
  {
    'sport_position_id': 116,
    'sports_id': 6,
    'label': 'Slapper',
    'abbrev': 'Slapp',
  },
  {
    'sport_position_id': 117,
    'sports_id': 10,
    'label': 'Center',
    'abbrev': 'C',
  },
  {
    'sport_position_id': 118,
    'sports_id': 10,
    'label': 'Goalie',
    'abbrev': 'G',
  },
  {
    'sport_position_id': 119,
    'sports_id': 10,
    'label': 'Left Defenseman',
    'abbrev': 'LD',
  },
  {
    'sport_position_id': 120,
    'sports_id': 10,
    'label': 'Left Wing',
    'abbrev': 'LW',
  },
  {
    'sport_position_id': 121,
    'sports_id': 10,
    'label': 'Right Defenseman',
    'abbrev': 'RD',
  },
  {
    'sport_position_id': 122,
    'sports_id': 10,
    'label': 'Right Wing',
    'abbrev': 'RW',
  },
  {
    'sport_position_id': 123,
    'sports_id': 12,
    'label': 'Attack',
    'abbrev': 'A',
  },
  {
    'sport_position_id': 124,
    'sports_id': 12,
    'label': 'Defense',
    'abbrev': 'D',
  },
  {
    'sport_position_id': 125,
    'sports_id': 12,
    'label': 'Draw Specialist',
    'abbrev': 'Draw',
  },
  {
    'sport_position_id': 126,
    'sports_id': 12,
    'label': 'Goalie',
    'abbrev': 'G',
  },
  {
    'sport_position_id': 127,
    'sports_id': 12,
    'label': 'Midfielder',
    'abbrev': 'M',
  },
  {
    'sport_position_id': 128,
    'sports_id': 14,
    'label': 'Attacking Midfielder',
    'abbrev': 'AM',
  },
  {
    'sport_position_id': 129,
    'sports_id': 14,
    'label': 'Center Back',
    'abbrev': 'CB',
  },
  {
    'sport_position_id': 130,
    'sports_id': 14,
    'label': 'Defensive Midfielder',
    'abbrev': 'Def M',
  },
  {
    'sport_position_id': 131,
    'sports_id': 14,
    'label': 'Forward',
    'abbrev': 'FW',
  },
  {
    'sport_position_id': 132,
    'sports_id': 14,
    'label': 'Goalkeeper',
    'abbrev': 'GK',
  },
  {
    'sport_position_id': 133,
    'sports_id': 14,
    'label': 'Outside Back',
    'abbrev': 'OB',
  },
  {
    'sport_position_id': 134,
    'sports_id': 14,
    'label': 'Outside Midfielder',
    'abbrev': 'OM',
  },
  {
    'sport_position_id': 135,
    'sports_id': 16,
    'label': 'Doubles',
    'abbrev': 'Doubl',
  },
  {
    'sport_position_id': 136,
    'sports_id': 16,
    'label': 'Singles',
    'abbrev': 'Singl',
  },
  {
    'sport_position_id': 137,
    'sports_id': 18,
    'label': '2M',
    'abbrev': '2M',
  },
  {
    'sport_position_id': 138,
    'sports_id': 18,
    'label': '2M Defender',
    'abbrev': '2M De',
  },
  {
    'sport_position_id': 139,
    'sports_id': 18,
    'label': 'Driver',
    'abbrev': 'Drive',
  },
  {
    'sport_position_id': 140,
    'sports_id': 18,
    'label': 'Flat',
    'abbrev': 'Flat',
  },
  {
    'sport_position_id': 141,
    'sports_id': 18,
    'label': 'Goalie',
    'abbrev': 'Goali',
  },
  {
    'sport_position_id': 142,
    'sports_id': 18,
    'label': 'Hole',
    'abbrev': 'Hole',
  },
  {
    'sport_position_id': 143,
    'sports_id': 18,
    'label': 'Point Guard',
    'abbrev': 'Point',
  },
  {
    'sport_position_id': 144,
    'sports_id': 18,
    'label': 'Utility',
    'abbrev': 'Utili',
  },
  {
    'sport_position_id': 145,
    'sports_id': 18,
    'label': 'Wing',
    'abbrev': 'Wing',
  },
  {
    'sport_position_id': 146,
    'sports_id': 20,
    'label': '101 lb',
    'abbrev': '101#',
  },
  {
    'sport_position_id': 147,
    'sports_id': 20,
    'label': '109 lb',
    'abbrev': '109#',
  },
  {
    'sport_position_id': 148,
    'sports_id': 20,
    'label': '116 lb',
    'abbrev': '116#',
  },
  {
    'sport_position_id': 149,
    'sports_id': 20,
    'label': '123 lb',
    'abbrev': '123#',
  },
  {
    'sport_position_id': 150,
    'sports_id': 20,
    'label': '130 lb',
    'abbrev': '130#',
  },
  {
    'sport_position_id': 151,
    'sports_id': 20,
    'label': '136 lb',
    'abbrev': '136#',
  },
  {
    'sport_position_id': 152,
    'sports_id': 20,
    'label': '143 lb',
    'abbrev': '143#',
  },
  {
    'sport_position_id': 153,
    'sports_id': 20,
    'label': '155 lb',
    'abbrev': '155#',
  },
  {
    'sport_position_id': 154,
    'sports_id': 20,
    'label': '170 lb',
    'abbrev': '170#',
  },
  {
    'sport_position_id': 155,
    'sports_id': 20,
    'label': '191 lb',
    'abbrev': '191#',
  },
  {
    'sport_position_id': 156,
    'sports_id': 21,
    'label': '125 lb',
    'abbrev': '125#',
  },
  {
    'sport_position_id': 157,
    'sports_id': 21,
    'label': '133 lb',
    'abbrev': '133#',
  },
  {
    'sport_position_id': 158,
    'sports_id': 21,
    'label': '141 lb',
    'abbrev': '141#',
  },
  {
    'sport_position_id': 159,
    'sports_id': 21,
    'label': '149 lb',
    'abbrev': '149#',
  },
  {
    'sport_position_id': 160,
    'sports_id': 21,
    'label': '157 lb',
    'abbrev': '157#',
  },
  {
    'sport_position_id': 161,
    'sports_id': 21,
    'label': '165 lb',
    'abbrev': '165#',
  },
  {
    'sport_position_id': 162,
    'sports_id': 21,
    'label': '174 lb',
    'abbrev': '174#',
  },
  {
    'sport_position_id': 163,
    'sports_id': 21,
    'label': '184 lb',
    'abbrev': '184#',
  },
  {
    'sport_position_id': 164,
    'sports_id': 21,
    'label': '197 lb',
    'abbrev': '197#',
  },
  {
    'sport_position_id': 165,
    'sports_id': 21,
    'label': '285 lb',
    'abbrev': '285#',
  },
  {
    "sport_position_id": 171,
    "sports_id": 24,
    "label": "10000M",
    "abbrev": "10000M"
  },
  {
    "sport_position_id": 172,
    "sports_id": 24,
    "label": "1000M",
    "abbrev": "1000M"
  },
  {
    "sport_position_id": 173,
    "sports_id": 24,
    "label": "100M",
    "abbrev": "100M"
  },
  {
    "sport_position_id": 174,
    "sports_id": 24,
    "label": "100M HH",
    "abbrev": "100M HH"
  },
  {
    "sport_position_id": 175,
    "sports_id": 24,
    "label": "110M HH",
    "abbrev": "110M HH"
  },
  {
    "sport_position_id": 176,
    "sports_id": 24,
    "label": "1500M",
    "abbrev": "1500M"
  },
  {
    "sport_position_id": 177,
    "sports_id": 24,
    "label": "1600M",
    "abbrev": "1600M"
  },
  {
    "sport_position_id": 178,
    "sports_id": 24,
    "label": "200M",
    "abbrev": "200M"
  },
  {
    "sport_position_id": 179,
    "sports_id": 24,
    "label": "3000M",
    "abbrev": "3000M"
  },
  {
    "sport_position_id": 180,
    "sports_id": 24,
    "label": "300M",
    "abbrev": "300M"
  },
  {
    "sport_position_id": 181,
    "sports_id": 24,
    "label": "300MH",
    "abbrev": "300MH"
  },
  {
    "sport_position_id": 182,
    "sports_id": 24,
    "label": "3200M",
    "abbrev": "3200M"
  },
  {
    "sport_position_id": 183,
    "sports_id": 24,
    "label": "400M",
    "abbrev": "400M"
  },
  {
    "sport_position_id": 184,
    "sports_id": 24,
    "label": "400MH",
    "abbrev": "400MH"
  },
  {
    "sport_position_id": 185,
    "sports_id": 24,
    "label": "4x100MR",
    "abbrev": "4x100MR"
  },
  {
    "sport_position_id": 186,
    "sports_id": 24,
    "label": "4x200MR",
    "abbrev": "4x200MR"
  },
  {
    "sport_position_id": 187,
    "sports_id": 24,
    "label": "4x400MR",
    "abbrev": "4x400MR"
  },
  {
    "sport_position_id": 188,
    "sports_id": 24,
    "label": "4x800MR",
    "abbrev": "4x800MR"
  },
  {
    "sport_position_id": 189,
    "sports_id": 24,
    "label": "55M",
    "abbrev": "55M"
  },
  {
    "sport_position_id": 190,
    "sports_id": 24,
    "label": "55M HH",
    "abbrev": "55M HH"
  },
  {
    "sport_position_id": 191,
    "sports_id": 24,
    "label": "5K",
    "abbrev": "5K"
  },
  {
    "sport_position_id": 192,
    "sports_id": 24,
    "label": "600M",
    "abbrev": "600M"
  },
  {
    "sport_position_id": 193,
    "sports_id": 24,
    "label": "60M",
    "abbrev": "60M"
  },
  {
    "sport_position_id": 194,
    "sports_id": 24,
    "label": "60MH",
    "abbrev": "60MH"
  },
  {
    "sport_position_id": 195,
    "sports_id": 24,
    "label": "800M",
    "abbrev": "800M"
  },
  {
    "sport_position_id": 196,
    "sports_id": 24,
    "label": "Decathlon",
    "abbrev": "Decathlon"
  },
  {
    "sport_position_id": 197,
    "sports_id": 24,
    "label": "Discus",
    "abbrev": "Discus"
  },
  {
    "sport_position_id": 198,
    "sports_id": 24,
    "label": "Distance & PR",
    "abbrev": "Distance & PR"
  },
  {
    "sport_position_id": 199,
    "sports_id": 24,
    "label": "DMR",
    "abbrev": "DMR"
  },
  {
    "sport_position_id": 200,
    "sports_id": 24,
    "label": "Hammer",
    "abbrev": "Hammer"
  },
  {
    "sport_position_id": 201,
    "sports_id": 24,
    "label": "Heptathlon",
    "abbrev": "Heptathlon"
  },
  {
    "sport_position_id": 202,
    "sports_id": 24,
    "label": "HJ",
    "abbrev": "HJ"
  },
  {
    "sport_position_id": 203,
    "sports_id": 24,
    "label": "Javelin",
    "abbrev": "Javelin"
  },
  {
    "sport_position_id": 204,
    "sports_id": 24,
    "label": "LJ",
    "abbrev": "LJ"
  },
  {
    "sport_position_id": 205,
    "sports_id": 24,
    "label": "Other",
    "abbrev": "Other"
  },
  {
    "sport_position_id": 206,
    "sports_id": 24,
    "label": "Pentathlon",
    "abbrev": "Pentathlon"
  },
  {
    "sport_position_id": 207,
    "sports_id": 24,
    "label": "Pole Vault",
    "abbrev": "Pole Vault"
  },
  {
    "sport_position_id": 208,
    "sports_id": 24,
    "label": "Road Race",
    "abbrev": "Road Race"
  },
  {
    "sport_position_id": 209,
    "sports_id": 24,
    "label": "Shot",
    "abbrev": "Shot"
  },
  {
    "sport_position_id": 210,
    "sports_id": 24,
    "label": "SMR",
    "abbrev": "SMR"
  },
  {
    "sport_position_id": 211,
    "sports_id": 24,
    "label": "Steeplechase",
    "abbrev": "Steeplechase"
  },
  {
    "sport_position_id": 212,
    "sports_id": 24,
    "label": "TJ",
    "abbrev": "TJ"
  },
  {
    "sport_position_id": 213,
    "sports_id": 24,
    "label": "Weight Throw",
    "abbrev": "Weight Throw"
  },
  {
    "sports_id": 25,
    "label": "Golfer",
    "abbrev": "Golfer",
    "sport_position_id": 215
  },
  {
    "sports_id": 26,
    "label": "Golfer",
    "abbrev": "Golfer",
    "sport_position_id": 216
  },
  {
    "sports_id": 27,
    "label": "Coxswain",
    "abbrev": "Coxswain",
    "sport_position_id": 217
  },
  {
    "sports_id": 27,
    "label": "Lightweight",
    "abbrev": "Lightweight",
    "sport_position_id": 218
  },
  {
    "sports_id": 27,
    "label": "Open Weight",
    "abbrev": "Open Weight",
    "sport_position_id": 219
  },
  {
    "sports_id": 27,
    "label": "Heavyweight",
    "abbrev": "Heavyweight",
    "sport_position_id": 220
  },
  {
    "sports_id": 28,
    "label": "Coxswain",
    "abbrev": "Coxswain",
    "sport_position_id": 221
  },
  {
    "sports_id": 28,
    "label": "Lightweight",
    "abbrev": "Lightweight",
    "sport_position_id": 222
  },
  {
    "sports_id": 28,
    "label": "Open Weight",
    "abbrev": "Open Weight",
    "sport_position_id": 223
  },
  {
    "sport_position_id": 224,
    "sports_id": 29,
    "label": "1m Springboard",
    "abbrev": "1m Springboard",
    "ncsa_code": "1m Springboard"
  },
  {
    "sport_position_id": 225,
    "sports_id": 29,
    "label": "3m Springboard",
    "abbrev": "3m Springboard",
    "ncsa_code": "3m Springboard"
  },
  {
    "sport_position_id": 226,
    "sports_id": 29,
    "label": "Platform",
    "abbrev": "Platform",
    "ncsa_code": "Platform"
  },
  {
    "sport_position_id": 227,
    "sports_id": 30,
    "label": "1m Springboard",
    "abbrev": "1m Springboard",
    "ncsa_code": "1m Springboard"
  },
  {
    "sport_position_id": 228,
    "sports_id": 30,
    "label": "3m Springboard",
    "abbrev": "3m Springboard",
    "ncsa_code": "3m Springboard"
  },
  {
    "sport_position_id": 229,
    "sports_id": 30,
    "label": "Platform",
    "abbrev": "Platform",
    "ncsa_code": "Platform"
  }
];

const mapUngroupedSports: Map<number, Map<number, any>> = new Map();

for (const sport of ungroupedSports) {
  const { sport_position_id: position, sports_id: sportId, ...values } = sport;
  const value = new Map([[sportId, values]]);
  mapUngroupedSports.set(position, value);
}

export const SPORT_LIST = [
  {
    name: "Track & Field",
    id: 24,
    shortName: 'TF',
    gender: 'unisex',
  },
  {
    name: "Women's Volleyball",
    id: 2,
    shortName: 'FVB',
    gender: 'F',
  },
  {
    name: "Men's Volleyball",
    id: 3,
    shortName: 'MVB',
    gender: 'M',
  },
  {
    name: "Women's Basketball",
    id: 4,
    shortName: 'FBB',
    gender: 'F',
  },
  {
    name: "Men's Basketball",
    id: 5,
    shortName: 'MBB',
    gender: 'M',
  },
  {
    name: "Women's Softball",
    id: 6,
    shortName: 'FSB',
    gender: 'F',
  },
  {
    name: "Women's Field Hockey",
    id: 8,
    shortName: 'FFH',
    gender: 'F',
  },
  {
    name: "Women's Ice Hockey",
    id: 10,
    shortName: 'FIH',
    gender: 'F',
  },
  {
    name: "Men's Ice Hockey",
    id: 11,
    shortName: 'MIH',
    gender: 'M',
  },
  {
    name: "Women's Lacrosse",
    id: 12,
    shortName: 'FL',
    gender: 'F',
  },
  {
    name: "Men's Lacrosse",
    id: 13,
    shortName: 'ML',
    gender: 'M',
  },
  {
    name: "Women's Soccer",
    id: 14,
    shortName: 'FS',
    gender: 'F',
  },
  {
    name: "Men's Soccer",
    id: 15,
    shortName: 'MS',
    gender: 'M',
  },
  {
    name: "Women's Tennis",
    id: 16,
    shortName: 'FT',
    gender: 'F',
  },
  {
    name: "Men's Tennis",
    id: 17,
    shortName: 'MT',
    gender: 'M',
  },
  {
    name: "Women's Water Polo",
    id: 18,
    shortName: 'FWP',
    gender: 'F',
  },
  {
    name: "Men's Water Polo",
    id: 19,
    shortName: 'MWP',
    gender: 'M',
  },
  {
    name: "Women's Wrestling",
    id: 20,
    shortName: 'FW',
    gender: 'F',
  },
  {
    name: "Men's Wrestling",
    id: 21,
    shortName: 'MW',
    gender: 'M',
  },
  {
    name: "Men's Baseball",
    id: 22,
    shortName: 'MB',
    gender: 'M',
  },
  {
    name: "Men's Football",
    id: 23,
    shortName: 'MF',
    gender: 'M',
  },
  {
    name: "Track & Field",
    id: 24,
    shortName: 'TF',
    gender: 'U',
  },
  {
    name: "Men's Golf",
    id: 25,
    shortName: 'GM',
    gender: 'M',
  },
  {
    name: "Women's Golf",
    id: 26,
    shortName: 'GF',
    gender: 'F',
  },
  {
    name: "Men's Rowing",
    id: 27,
    shortName: 'RM',
    gender: 'M',
  },
  {
    name: "Women's Rowing",
    id: 28,
    shortName: 'RF',
    gender: 'F',
  },
  {
    id: 29,
    name: "Men's Diving",
    shortName: "DM",
    gender: "M"
  },
  {
    id: 30,
    name: "Women's Diving",
    shortName: "DF",
    gender: "F"
  }
];

const mapSportList: Map<number, { name: string, id: number, shortName: string, gender: string }> = new Map();
for (const sport of SPORT_LIST) {
  mapSportList.set(sport.id, sport);
}

const testRanks: string[] = [
  // 'R1',
  // 'R2',
  // 'R3',
  // 'R4',
  // 'R5',
  // 'R6',
  // 'R7',
  // 'R8',
];

const testTasks: Task[] = [
  {
    title: 'Questionnaire',
    done: false,
  },
  {
    title: 'Call',
    done: true,
  },
  {
    title: 'Send invitation',
    done: true,
  }
]

export {
  mapUngroupedSports,
  testRanks,
  mapSportList,
  testTasks,
};
