import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSnackbar } from 'notistack';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ApplicationBar from '../blocks/ApplicationBar';
import MobileMainMenu from '../blocks/MobileMainMenu';
import DesktopMainMenu from '../blocks/DesktopMainMenu';

import Routes from '../../component/Routs';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { useAppStateValue } from '../../state/StateContext';

import ApiResponse from '../../api/ApiResponse';
import { loadInitData, AccountInitialDto, collegeCoachesList } from '../../api/account';
import { getCollegeTags } from '../../api/tags';
import { getTournamentsShortList } from '../../api/tournaments';
import { getCollegeGroups, getAthleteRanksForFiltersList, AthleteRankForFiltersList, CollegeGroupDto } from '../../api/ranks';
import { DRAWER_WIDTH, TOOLBAR_HEIGHT } from '../../constants';
import AthleteHelpers from '../../helpers/AthleteHelpers';
import { CollegeTasksDto, getCollegeTasks } from '../../api/tasks';
import { setGlobalSport } from '../../helpers/globals';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: DRAWER_WIDTH.desktop,
        flexShrink: 0,
      },
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerShift: {
      [theme.breakpoints.up('sm')]: {
        width: DRAWER_WIDTH.mobile,
        flexShrink: 0,
      },
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      width: `calc(100% - ${DRAWER_WIDTH.desktop}px)`,
      height: `cals(100vh - ${TOOLBAR_HEIGHT.desktop}px)`,
    },
    contentShift: {
      width: '100%',
      height: `cals(100vh - ${TOOLBAR_HEIGHT.mobile}px)`,
    },
  }),
);

export default function Layout() {
  const classes = useStyles();
  const [{ isVisibleTasks }, dispatch] = useAppStateValue();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    async function initialization() {
      const accountDto: ApiResponse<AccountInitialDto> = await loadInitData();


      const activeSport: any = {
        sports_id: accountDto?.data?.sports_id,
        college_program_id: accountDto?.data?.college_program_id
      };

      setGlobalSport(activeSport)

      const [collegeCoachesDto,
        collegeTagsDto,
        tournamentShortListDto,
        collegeGroupsDto,
        athleteRanksForFiltersList,
        collegeTasksDto,
      ] = await Promise.all([
        collegeCoachesList(),
        getCollegeTags(),
        getTournamentsShortList(),
        getCollegeGroups(),
        getAthleteRanksForFiltersList(activeSport.college_program_id),
        isVisibleTasks ? getCollegeTasks() : Promise.resolve({ success: true, data: [] as CollegeTasksDto[] } as ApiResponse<CollegeTasksDto[]>),
      ])

      if (accountDto.success
        && collegeCoachesDto.success
        && collegeTagsDto.success
        && tournamentShortListDto.success
        && collegeGroupsDto.success
        && athleteRanksForFiltersList.success
        && collegeTasksDto.success
      ) {
        const { data: account } = accountDto;
        const { data: collegeCoaches } = collegeCoachesDto;
        const collegeTags = collegeTagsDto.data?.filter(t => !t.date_deleted && t.tag_type !== 'done' && t.tag_type !== 'omit').sort(AthleteHelpers.reorderTags);
        const systemTags = collegeTagsDto.data?.filter(t => !t.date_deleted && t.tag_type === 'omit');
        const deletedTags = collegeTagsDto.data && collegeTagsDto.data.filter(t => t.date_deleted && t.tag_type !== 'done').sort(AthleteHelpers.reorderTags);
        const { data: tournaments } = tournamentShortListDto;
        const { data: ranksForFilter } = athleteRanksForFiltersList;
        const { data: groups } = collegeGroupsDto;
        const { data: tasks } = collegeTasksDto;
        let prepareGroups: any = groups?.map(group => {
          return {
            ...group,
            ranks: group.ranks.map(item => {
              if (item.values) {
                item.values = (item.values as string).split(' ');
                return item
              } else {
                return item
              }
            })
          }
        });

        dispatch({
          type: 'initState',
          value: {
            account,
            collegeTags,
            systemTags,
            deletedTags,
            activeSport,
            tournaments,
            collegeCoaches,
            collegeGroups: prepareGroups,
            ranksForFilter,
            tasks,
          },
        });
      } else {
        enqueueSnackbar('Cannot load initial data', { variant: 'error' });
      }
    }

    initialization();
  }, [dispatch, enqueueSnackbar]);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [resize, setResize] = React.useState(false);

  const handleResize = (): void => {
    setResize(!resize);
  };

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <ApplicationBar
          resize={resize}
          handleDrawerToggle={handleDrawerToggle}
        />
        <nav
          className={resize ? classes.drawerShift : classes.drawer}
          aria-label="mailbox folders"
        >
          <MobileMainMenu
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
          <DesktopMainMenu resize={resize} handleResize={handleResize} />
        </nav>
        <main
          style={{
            width: matches ? '100%' : (resize ? 'calc(100% - 73px)' : 'calc(100% - 235px)'),
            transition: !matches ? 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms' : '',
            minHeight: 'calc(100vh - 64px)',
          }}
        >
          <div className={classes.toolbar} />
          <Routes />
        </main>
      </div>
    </Router>
  );
}
