import Amplify, { Auth } from 'aws-amplify';
import { refreshUaToken } from './auth';
import { getGlobalIsUaUser, getGlobalProgram } from '../helpers/globals';

const {
  REACT_APP_ENV: environment,
  REACT_APP_API_URL: urlApiServer = '',
  REACT_APP_AWS_AUTH_REGION: region,
  REACT_APP_AWS_AUTH_USER_POOL_ID: userPoolId,
  REACT_APP_AWS_AUTH_USER_POOL_WEB_CLIENT_ID: userPoolWebClientId,
  REACT_APP_AWS_AUTH_FLOW_TYPE: authenticationFlowType,
  REACT_APP_NCSA_URL: urlNCSA = '',
  REACT_APP_FRONT_RUSH_URL: urlFrontRush,
} = process.env;

const config = {
  urlApiServer,
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId,
    authenticationFlowType,
  },
  urlNCSA,
  urlFrontRush,
  environment
};

// switch (environment) {
//   case 'prod':
//     config.urlApiServer = 'https://api.coach.club';
//     config.Auth = {
//       region: 'us-west-2',
//       userPoolId: 'us-west-2_t1IJy0kAO',
//       userPoolWebClientId: '6cjfb22iluv5mb3sdgf27hhqd7',
//       authenticationFlowType: 'USER_PASSWORD_AUTH',
//     };
//     config.urlFrontRush = 'https://universityathlete.com/ua-api/v4';
//     break;
//   case 'dev':
//     config.urlApiServer = 'https://cp.universityathlete.com/api/v1';
//     config.Auth = {
//       region: 'us-east-1',
//       userPoolId: 'us-east-1_XRPpY9SmG',
//       userPoolWebClientId: '5hlo8h13n5gao3ja1lcol3ljia',
//       authenticationFlowType: 'USER_PASSWORD_AUTH',
//     };
//     break;
//   default:
//     config.urlApiServer = 'http://localhost:3003/api/v1';
//     config.Auth = {
//       region: 'us-east-1',
//       userPoolId: 'us-east-1_XRPpY9SmG',
//       userPoolWebClientId: '5hlo8h13n5gao3ja1lcol3ljia',
//       authenticationFlowType: 'USER_PASSWORD_AUTH',
//     };
// }

Amplify.configure({
  Auth: config.Auth,
  API: {
    endpoints: [
      {
        name: 'legacyAPI',
        endpoint: config.urlApiServer,
        custom_header: async () => {
          if (getGlobalIsUaUser()) {
            if (!!localStorage.getItem('access_token')) {
              const expiresIn = localStorage.getItem('expires_in') as string;

              if (+expiresIn < Date.now()) {
                const refresh_token = localStorage.getItem('refresh_token') as string;

                let response = await refreshUaToken(refresh_token);

                if (response.success) {
                  const { data: newTokens } = response;

                  localStorage.setItem('access_token', newTokens.access_token)
                  localStorage.setItem('refresh_token', newTokens.refresh_token)
                  localStorage.setItem('expires_in', Date.now() + newTokens.expires_in)

                } else {
                  console.log(response.error);
                }
              }
            }

            const uaToken = localStorage.getItem('access_token');
            const sport = getGlobalProgram()
            return { auth: uaToken, sport: sport?.sports_id };
          }
          else {
            const session = await Auth.currentSession();
            const token = session.getAccessToken().getJwtToken();
            const sport = getGlobalProgram()
            console.log(sport)
            return { auth: token, sport: sport?.sports_id };
          }
        },
      },
    ],
  },
});

export default config;
