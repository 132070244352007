import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { makeStyles } from '@material-ui/core/styles';

import Login from './pages/Login';
import AdminAfterLogin from './pages/AdminAfterLogin';
import Landing from './pages/Landing';
import { AppBar, Box, Button, CssBaseline, IconButton, Toolbar, Typography } from '@material-ui/core';
import logo from '../assets/LogoNCSAwhite.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

  },
  logoContainer: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none',
    color: 'none',
  },
  logoLink: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    maxWidth: 140,
  },
  logoImg: {
    width: 130
  },
  navBtn: {
    color: '#fff',
    height: '100%',
  }
}));

export default function PublicPages() {
  const classes = useStyles();

  return (
    <Router>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar position="static" color='secondary'>
          <Toolbar>
            <div className={classes.logoContainer}>
              <Link className={`${classes.logoLink} ${classes.link}`} to="/">
                <img src={logo} alt={'logo'} className={classes.logoImg} />
              </Link>
            </div>

            <Box>
              <HashLink smooth to={'/#about'} className={classes.link}>
                <Button className={classes.navBtn}>
                  About
                </Button>
              </HashLink>
              <HashLink smooth to={'/#contact'} className={classes.link}>
                <Button className={classes.navBtn}>
                  Contact
                </Button>
              </HashLink>
              <Link to='/login' className={classes.link}>
                <Button className={classes.navBtn}>
                  Login
                </Button>
              </Link>
            </Box>
          </Toolbar>
        </AppBar>
      </div>

      <Switch>
        <Route path="/admin-login/:username/:password" component={AdminAfterLogin} />
        <Route path="/login" component={Login} />
        <Route path="/" component={Landing} />
      </Switch>
    </Router>
  );
}
