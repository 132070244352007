import { useState } from 'react';
import { Box, Button, Container, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import 'swiper/swiper.min.css';

import Twitter from '@material-ui/icons/Twitter';

import ios_app from '../../assets/landing/ios-app.png';
import android_app from '../../assets/landing/android-app.png';

import usa_field_hockey from '../../assets/landing/usa_field_hockey.png';
import usa_water_polo from '../../assets/landing/usa_water_polo.svg';
import usssa from '../../assets/landing/usssa.svg';
import usa_hockey from '../../assets/landing/usa_hockey.svg';
import alliance_fastpitch from '../../assets/landing/alliance_fastpitch.svg';
import img_academy from '../../assets/landing/img_academy.png';
import prep_hoops from '../../assets/landing/prep_hoops.svg';
import usa_lacrosse from '../../assets/landing/usa_lacrosse.svg';
import usa_baseball from '../../assets/landing/usa_baseball.svg';
import usys from '../../assets/landing/usys.png';
import pg from '../../assets/landing/pg.svg';
import headfirst from '../../assets/landing/headfirst.svg';


import slide1 from '../../assets/landing/slides/screen1.svg';
import slide2 from '../../assets/landing/slides/screen2.svg';
import slide3 from '../../assets/landing/slides/screen3.svg';
import slide4 from '../../assets/landing/slides/screen4.svg';
import slide5 from '../../assets/landing/slides/screen5.svg';
import slide6 from '../../assets/landing/slides/screen6.svg';
import slide7 from '../../assets/landing/slides/screen7.svg';
import slide8 from '../../assets/landing/slides/screen8.svg';
import slide9 from '../../assets/landing/slides/screen9.svg';
import device from '../../assets/landing/slides/device.svg';

import { DiscoverIcon } from '../../assets/landing/DiscoverIcon';
import { PlanIcon } from '../../assets/landing/PlanIcon';
import { EvaluateIcon } from '../../assets/landing/EvaluateIcon';
import { CollaborateIcon } from '../../assets/landing/CollaborateIcon';
import { GreatIcon } from '../../assets/landing/GreatIcon';
import { CommaIcon } from '../../assets/landing/CommaIcon';



const useStyles = makeStyles((theme) => ({
  whiteContainer: {
    backgroundColor: '#fff',
  },
  img: {
    width: '100%',
    maxWidth: 420,
  },
  linkToStore: {
    width: '100%',
    maxWidth: 170
  },
  title: {
    fontWeight: 500
  },
  subtitle: {
    marginBottom: 32
  },
  textByNCSA: {
    color: '#C4C4C4',
    marginBottom: 32
  },
  textInfo: {
    fontSize: 18,
    lineHeight: '22px',
    fontWeight: 300,
    marginBottom: 40,
  },
  text: {
    fontSize: 18,
    lineHeight: '22px',
  },
  videoContainer: {
    padding: '45px 0 90px'
  },
  videoWrap: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',
    paddingTop: 30,
    height: 0,
    marginTop: 40,

    '& iframe': {
      position: 'absolute',
      border: 0,
      height: '100%',
      left: 0,
      top: 0,
      width: '100%',
    }
  },
  iconCards: {
    fontSize: 72,
    color: '#e8e6e6',
    marginBottom: 24,
  },
  contactUs: {
    backgroundColor: '#005DA8',
    color: '#fff',
    padding: 20,
    paddingTop: 40,
    textAlign: 'center',
    height: '100%',
  },
  customerSupport: {
    backgroundColor: '#ED8B01',
    color: '#fff',
    padding: 20,
    textAlign: 'center',
  },
  textField: {
    '& .MuiFormLabel-root': {
      color: '#fff',
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff',
    }
  },
  containerPadding: {
    padding: '56px 0',
  },
  footerWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  feedbackForm: {
    '& .MuiDialog-paperWidthMd': {
      width: '100%'
    },
    '& .MuiDialog-paperScrollPaper': {
      height: '70%',
    }
  },
  swiperWrap: {
    width: '100%',
    maxWidth: 225,
    position: 'relative',
    margin: '20px 0'
  },
  swiper: {
    position: 'absolute',
    top: 10,
    left: 5,
    width: '100%',
    height: '100%'
  }
}));


export default function Login() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const slideList = [slide1, slide2, slide3, slide4, slide5, slide6, slide7, slide8, slide9]

  return (
    <Box>
      <Box className={classes.whiteContainer}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} md={5}>
              <Typography variant="h3" className={classes.title} style={{ marginTop: 48 }}>
                Coach Packet 3
              </Typography>
              <Typography variant="h4" className={`${classes.title} ${classes.textByNCSA}`}>
                by NCSA
              </Typography>
              <Typography className={classes.textInfo}>
                Everything you need to find and evaluate
                athletes at large events. <br />
                Coach Packet 3 provides you with digital access
                to the athletes and roster data that you are
                accustomed to getting in a paper packet at events. <br />
                Integration with NCSA recruiting profiles provides
                you with even more data than you typically receive.
                Data such as highlight videos, transcripts, contact
                info and more. <br />
                The app is free to download and there is no cost
                to attend most events. Download Coach Packet 3 by NCSA today.
              </Typography>
              <Box display={'flex'}>
                <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/ua/app/coach-packet-3-by-ncsa/id1540482512#?platform=iphone" style={{ marginRight: 10 }}>
                  <img src={ios_app} alt="ios_app" className={classes.linkToStore} />
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=org.ncsasports.coachpacket3">
                  <img src={android_app} alt="android_app" className={classes.linkToStore} />
                </a>
              </Box>
            </Grid>
            <Grid item container xs={12} md={7} justifyContent={'center'}>
              <div className={classes.swiperWrap}>
                <img src={device} alt="device" style={{ position: 'relative', zIndex: 2 }} />
                <Swiper
                  className={classes.swiper}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                  }}
                  modules={[Autoplay]}
                >
                  {slideList.map((item, index) => <SwiperSlide key={index}><img src={item} alt={`slide${index}`} /></SwiperSlide>)}
                </Swiper>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className={classes.videoContainer}>
        <Container maxWidth="md">
          <Box display={'flex'} justifyContent={'center'} alignItems='center' flexDirection={'column'}>
            <Box>
              <Typography variant="h4" component='span' className={classes.title}>
                See It In
              </Typography>
              &nbsp;&nbsp;
              <Typography variant="h4" component='span' color='primary' className={classes.title}>Action</Typography>
            </Box>


            <Box className={classes.videoWrap}>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/GC3hehQHDwU"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box className={`${classes.whiteContainer} ${classes.containerPadding}`} id='about'>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <Box textAlign={'center'}>
                <DiscoverIcon />
                <Typography variant="h5" className={classes.title} style={{ margin: '16px 0' }}>Discover</Typography>
                <Typography className={classes.text}>
                  Access team rosters synced
                  with NCSA profiles. Get complete
                  academic info on each prospect.
                </Typography>
              </Box>

            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Box textAlign={'center'}>
                <PlanIcon />
                <Typography variant="h5" className={classes.title} style={{ margin: '16px 0' }}>Plan</Typography>
                <Typography className={classes.text}>
                  Get the schedule before
                  the event. Plan ahead with your
                  staff to maximize your time.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Box textAlign={'center'}>
                <EvaluateIcon />
                <Typography variant="h5" className={classes.title} style={{ marginBottom: 16 }}>Evaluate</Typography>
                <Typography className={classes.text}>
                  Identify and evaluate
                  prospects from the stands.
                  Make notes on the fly.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Box textAlign={'center'}>
                <CollaborateIcon />
                <Typography variant="h5" className={classes.title} style={{ marginBottom: 16 }}>Collaborate</Typography>
                <Typography className={classes.text}>
                  Share and compare notes
                  with your staff in real time - tags
                  and notes auto-sync in the app.
                </Typography>
              </Box>

            </Grid>

          </Grid>
        </Container>
      </Box>

      <Box className={classes.containerPadding}>
        <Container maxWidth="lg">

          <Box textAlign={'center'} marginBottom={5}>
            <Typography variant="h4" component='span' color='primary' className={classes.title} gutterBottom>Events</Typography>
            <Typography variant="subtitle1" className={classes.text}>Coach Packet by NCSA is the preferred digital recruiting tool of these NCSA partners:</Typography>
          </Box>

          <Grid container justifyContent='center' spacing={5}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usa_field_hockey} alt="usa_field_hockey" />
                </Box>

                <Typography variant="subtitle1" className={classes.text}>
                  USA Field Hockey
                </Typography>
              </Box>

            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usa_water_polo} alt="usa_water_polo" />
                </Box>
                <Typography variant="subtitle1" className={classes.text}>
                  USA Water Polo
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usssa} alt="usssa" />
                </Box>
                <Typography variant="subtitle1" className={classes.text}>
                  United States Specialty
                  Sports Association
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usa_hockey} alt="usa_hockey" />
                </Box>
                <Typography variant="subtitle1" className={classes.text}>
                  The National Governing Body
                  of Ice Hockey in the United States
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={alliance_fastpitch} alt="alliance_fastpitch" />
                </Box>
                <Typography variant="subtitle1" className={classes.text}>
                  The Alliance Fastpitch
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={img_academy} alt="img_academy" />
                </Box>
                <Typography variant="subtitle1" className={classes.text}>
                  IMG Academy
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} >
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={prep_hoops} alt="prep_hoops" />
                </Box>

                <Typography className={classes.text}>
                  Prep Hoops
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usa_lacrosse} alt="usa_lacrosse" />
                </Box>

                <Typography className={classes.text}>
                  USA Lacrosse
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usa_baseball} alt="usa_baseball" />
                </Box>

                <Typography className={classes.text}>
                  USA Baseball
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usys} alt="usys" />
                </Box>

                <Typography className={classes.text}>
                  US Youth Soccer
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={pg} alt="pg" />
                </Box>

                <Typography className={classes.text}>
                  Perfect Game
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={headfirst} alt="headfirst" />
                </Box>

                <Typography className={classes.text}>
                  Headfirst Honor Roll Camps
                </Typography>
              </Box>
            </Grid>

          </Grid>
        </Container>
      </Box>


      <Box className={`${classes.whiteContainer} ${classes.containerPadding}`}>
        <Container maxWidth="lg">
          <Box marginBottom={5} display={'flex'} justifyContent={'center'}>
            <Typography variant="h4" className={classes.title}>What Coaches</Typography>&nbsp;&nbsp;
            <Typography variant="h4" component='h4' color='primary' className={classes.title} gutterBottom>Say</Typography>
          </Box>

          <Grid container justifyContent='center' spacing={3}>
            <Grid item xs={12} sm={6} lg={4} >
              <Box textAlign={'center'}>
                <GreatIcon />
                <Box marginBottom={5}>
                  <Typography variant="h6" className={classes.title} style={{ margin: '16px 0' }}>Brittany Johnson <br />
                    Saint Leo University</Typography>
                  <Typography className={classes.text} >
                    I absolutely love the app and do not want to go back to paper.
                  </Typography>
                </Box>
                <CommaIcon />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Box textAlign={'center'}>
                <GreatIcon />
                <Box marginBottom={5}>
                  <Typography variant="h6" className={classes.title} style={{ margin: '16px 0' }}>Blaine Taylor <br />
                    University of California Irvine</Typography>
                  <Typography className={classes.text} >
                    I like that you are helping to streamline the process of figuring out where kids are at these events.
                  </Typography>
                </Box>
                <CommaIcon />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Box textAlign={'center'}>
                <GreatIcon />
                <Box marginBottom={5}>
                  <Typography variant="h6" className={classes.title} style={{ margin: '16px 0' }}>Jon Hawkins <br />
                    Northwest Nazarene University</Typography>
                  <Typography className={classes.text} >
                    Developers keep it up. You’re making my life easier.
                  </Typography>
                </Box>
                <CommaIcon />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className={classes.containerPadding}>
        <Container maxWidth="lg">
          <Box textAlign={'center'}>
            <Typography className={classes.text} >
              Discover more
            </Typography>
            <Typography variant="h4" color='primary' className={classes.title} gutterBottom>Athletes</Typography>
            <Typography className={classes.text} style={{ marginBottom: 20 }}>
              Get free access to the world's largest network of college-ready recruits.
            </Typography>

            <Button variant="contained" color='primary' href='https://coach.ncsasports.org/coach/coachrms/login'>Find out more</Button>
          </Box>
        </Container>
      </Box>

      <Box >
        <Grid container justifyContent='center'>
          <Grid item xs={12} md={6}>
            <Box className={classes.contactUs}>
              <Typography variant="h4" component='h4' className={classes.title} gutterBottom>Contact Us</Typography>
              <Typography className={classes.text}>
                Questions or issues? Please call us:
              </Typography>
              <Typography className={classes.text}>
                866-495-7089
              </Typography>

            </Box>
          </Grid>
          <Grid item xs={12} md={6}>

            <Box className={classes.customerSupport} id='contact'>
              <Typography variant="h4" component='h4' className={classes.title}>Customer Support</Typography>
              <Typography variant="body1" className={classes.subtitle} gutterBottom>How can we help? Please, leave feedback for Coach Packet</Typography>

              <Button variant="contained" color="secondary" onClick={handleClickOpen}>
                Leave feedback
              </Button>

              <Dialog open={open} onClose={handleClose} maxWidth={'md'} aria-labelledby="form-dialog-title" className={classes.feedbackForm}>
                <DialogTitle id="form-dialog-title">Feedback</DialogTitle>
                <DialogContent>
                  <iframe loading="lazy" frameBorder="0" src="https://app.mopinion.com/survey/public/take-survey/44a3ed13cebe8afddb1ecd72dc7d9a9981a7c0df" width="100%" height="100%" >
                  </iframe>
                </DialogContent>
              </Dialog>

            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.containerPadding} style={{ backgroundColor: '#a0a09f', color: '#fff' }}>
        <Container maxWidth="lg">
          <Box className={classes.footerWrap}>
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/ncsa_coach"><Twitter style={{ color: '#fff', marginBottom: 5 }} /></a>
            <Typography className={classes.text}>
              866-495-7089&nbsp;&nbsp;|&nbsp;&nbsp;1333 N Kingsbury St. Chicago IL 60642
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box className={classes.whiteContainer} textAlign={'center'} style={{ color: '#999997', padding: 5 }}>
        <Typography>
          © 2022 <a target="_blank" rel="noopener noreferrer" href="https://www.ncsasports.org/" style={{ color: '#999997' }}>NCSA College Recruiting</a>
        </Typography>
      </Box>
    </Box >

  );
}
