import { CollegeCoaches } from '../../../../api/account';
import { AthletesListAthleteType } from '../../../../api/athletes';
import { AthleteRankDto, CollegeGroupDto } from '../../../../api/ranks';
import { CollegeTagsDto } from '../../../../api/tags';
import AthleteHelpers from '../../../../helpers/AthleteHelpers';

export default function tranformAthletesIntoExcel (
  athletes: AthletesListAthleteType[],
  collegeGroups: CollegeGroupDto[],
  collegeTags: CollegeTagsDto[],
  coaches: CollegeCoaches[]
): any[] {

  let result: any[] = [];

  let tagsOrdered = [
    ...collegeTags.filter(t => t.primary),
    ...collegeTags.filter(t => !t.primary),
  ]
  let rankGroups = collegeGroups.filter(g => !g.date_deleted)
  let groupTitles: any = {}
  rankGroups.forEach(g => {
    // we can't have same group names
    if(groupTitles[g.title]) {
      g.title = `${g.title} (${ groupTitles[g.title]++ })`
    } else {
      groupTitles[g.title] = true
    }
  })

  const buildGroupRankValues = (ranks: AthleteRankDto[], participant: CollegeCoaches): Record<string, string> => {
    let res: Record<string, string> = {}
    rankGroups.forEach(group => {
      let hasRank = false

      let athleteRanks = group.ranks.map(r => {
        let rankValue = ranks.find(rank => rank.college_rank_id === +r.college_rank_id && rank.users_id === participant.users_id)
        if(rankValue) {
          hasRank = true
        }
        return rankValue 
          ? `${r.title}: ${rankValue.value}`
          : null
      }).filter(Boolean)

      const title = group.title === 'General' ? 'Ratings' : `Rating Group: ${group.title}`

      res[title] = hasRank? athleteRanks.join(' '): ''
    })

    return res
  }

  athletes.forEach(athlete => {
    let participants = coaches.filter(coach => {
      return athlete.notes.find(note => note.users_id === coach.users_id)
        || athlete.tags.find(tag => tag.users_id === coach.users_id)
        || athlete.ranks?.find(rank => rank.users_id === coach.users_id)
    })
    if(!participants.length) {
      participants = [{
        users_id: 0, initials: '', name: '', coach: '', college_user_id: 0, color: ''
      }]
    }

    let participant = participants[0]

    result.push({
      'First Name': athlete.first,
      'Last Name': athlete.last,
      'Email': athlete.email,
      'Gradyear': athlete.gradyear,
      'Height':  athlete.height? AthleteHelpers.toInches(athlete.height) : '',
      'Uniform': athlete.uniform1,
      'Club Name': athlete.club_name,
      'Team Name': athlete.team_name,
      'Position 1': athlete.position1_abbrev,
      'Position 2': athlete.position2_abbrev,
      'Address': athlete.haddress1,
      'City': athlete.hcity,
      'State': athlete.hstate,
      'Zip': athlete.hzip,
      // 'Home Phone' ?
      'Cell Phone': athlete.phonec,
      'Scholarship': athlete.scholarship_status,
      'Coach Name': participant.name,
      'College Tags': tagsOrdered.filter(t => {
        return athlete.tags.find(tag =>  tag.college_tag_id === t.college_tag_id && tag.users_id === participant.users_id)
      }).map(t => t.title).join(', '),
      // group ranks
      ...buildGroupRankValues(athlete.ranks || [], participant),
      'Note': athlete.notes.filter(note => note.users_id === participant.users_id).map(note => note.note).join('\r\n')
    })

    // all next coaches in a new line
    for (let index = 1; index < participants.length; index++) {
      const participant = participants[index];

      result.push({
        'Coach Name': participant.name,
        'College Tags': tagsOrdered.filter(t => {
          return athlete.tags.find(tag =>  tag.college_tag_id === t.college_tag_id && tag.users_id === participant.users_id)
        }).map(t => t.title).join(', '),
        // group ranks
        ...buildGroupRankValues(athlete.ranks || [], participant),
        'Note': athlete.notes.filter(note => note.users_id === participant.users_id).map(note => note.note).join('\r\n')
      })
    }

  })

  return result
}
