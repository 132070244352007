import React, { useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import BaseCheckbox from '../../shared/BaseCheckbox';
import usePersonalInfoStyle from './usePersonalInfoStyle';
import { AthletesListAthleteType, sendAthleteProfileStatistic } from '../../../../api/athletes';
import AthleteHelpers from '../../../../helpers/AthleteHelpers';
import DialogClubNote from '../athlete-club-note/DialogClubNote';
import AthleteSummary from '../athlete-summary/AthleteSummary';

import ncsa_logo from '../../../../assets/ncsa_logo-circle.svg';
import { useAppStateValue } from '../../../../state/StateContext';

const prepareAthleteInfo = (athlete: AthletesListAthleteType, className: string): any => {
  const gradYear = athlete.gradyear ? `'${athlete.gradyear.toString().slice(2)}` : '';
  const height = athlete.height ? AthleteHelpers.toInches(athlete.height) : '';
  const state = athlete.hstate || ' ';
  const gpa = athlete.gpa || '';

  return <p className={className} style={{ margin: 0 }}>
    &nbsp;{`${athlete.position1_abbrev || ''} ${gradYear} ${height} ${state} ${gpa}`}
  </p>;
};

export type AthletePersonalInfoProps = {
  athlete: AthletesListAthleteType;
  showMoreInfo: boolean;
  athletesChecked: number[];
  urlNCSA: string;
  handleAthleteChecked: (athleteId: number, isCheck: boolean) => void;
  setShowMoreInfo: (isShow: boolean) => void;
  isAthleteProfile?: boolean;
}
const AthletePersonalInfo = (props: AthletePersonalInfoProps) => {
  const { athlete, showMoreInfo, setShowMoreInfo, athletesChecked, urlNCSA, handleAthleteChecked, isAthleteProfile } = props;
  const [openDialogForm, setOpenDialofForm] = useState(false);
  const classes = usePersonalInfoStyle();

  const checked = useMemo(() => {
    return athletesChecked.includes(athlete.athlete_master_id);
  }, [athletesChecked, athlete.athlete_master_id]);

  const openLink = () => {
    if (athlete.ncsa_client_id) {
      sendAthleteProfileStatistic(athlete.athlete_master_id)
      const link = `${urlNCSA}/clientrms/athletes/${athlete.ncsa_client_id}?session_token=${athlete.ncsa_athlete_token}`;
      window.open(link, '_blank');
    }
  };
  const handleClickOpenForm = () => {
    setOpenDialofForm(true);
  };
  const handleCloseForm = () => {
    setOpenDialofForm(false);
  };

  const handleCheckbox = (isCheck: boolean) => {
    handleAthleteChecked(athlete.athlete_master_id, isCheck);
  };

  return (
    <>
      <div className={classes.personalInfoContainer}>
        <div className={classes.personalInfo}>

          {!isAthleteProfile && <BaseCheckbox
            isCheck={checked}
            handleCheckbox={handleCheckbox}
            customStyle={{ margin: '3px 8px 3px 5px', borderRadius: 5, padding: 6, }}
          />}

          <Box display={'flex'} flexDirection={'column'} style={{ margin: !isAthleteProfile ? 0 : '0 auto 0 38px' }}>
            <div className={classes.container} style={{ paddingTop: 8, }}>
              <Typography classes={{ root: !isAthleteProfile ? classes.linkNameText : classes.nameText }}
                onClick={() => {
                  !isAthleteProfile && setShowMoreInfo(!showMoreInfo);
                }}
              >
                {`${athlete.last}, ${athlete.first}`}
              </Typography>
              {prepareAthleteInfo(athlete, classes.blockText)}
            </div>
            <div className={classes.container}>
              <Typography onClick={handleClickOpenForm} classes={{ root: `${classes.blockText} ${classes.decoration}` }}>{athlete.team_name || ''}</Typography>
            </div>
          </Box>

          {/* {!isAthleteProfile && <IconButton className={classes.iconHide} onClick={() => {
            setShowMoreInfo(!showMoreInfo);
          }}>
            {!showMoreInfo && <ExpandMoreIcon color={'secondary'} />}
            {showMoreInfo && <ExpandLessIcon color={'secondary'} />}
          </IconButton>} */}
          {athlete.ncsa_client_id &&
            <img
              src={ncsa_logo}
              alt={`ncsa_logo`}
              loading="lazy"
              onClick={openLink}
              className={classes.ncsaLogo}
            />}
        </div>

        <AthleteSummary athleteId={athlete.athlete_master_id} notes={athlete.notes} />
      </div>

      {openDialogForm && <DialogClubNote
        id="ringtone-menu"
        open={openDialogForm}
        onClose={handleCloseForm}
        athlete={athlete}
      />}
    </>
  );
};

export default AthletePersonalInfo;
