import AthleteHelpers from '../../../../helpers/AthleteHelpers';

const general = [
  [
    { title: 'Grad. Year', name: 'gradyear', value: '' },
    { title: 'B-day', name: 'birthdate', value: '', fn: AthleteHelpers.formatDateToLocaleDateString },
    { title: 'Email', name: 'email', value: '' },
    { title: 'Cell phone', name: 'phonec', value: '' },
  ],
  [
    { title: 'Address', name: 'haddress1', value: '' },
    { title: 'City', name: 'hcity', value: '' },
    { title: 'State', name: 'hstate', value: '' },
    { title: 'Zip', name: 'hzip', value: '' },
  ],
];

const clubTeam = [
  [
    { title: 'Club name', name: 'club_name', value: '' },
    { title: 'Recr. Coord. name', name: 'coordinator_name', value: '' },
    { title: 'Recr. Coord. email', name: 'coordinator_email', value: '' },
    { title: 'Club email', name: 'club_email', value: '' },
    { title: 'Club Dir. name', name: 'club_director_name', value: '' },
    { title: 'Club Dir. email', name: 'club_director_email', value: '' },
    { title: 'Club Dir. phone', name: 'club_director_phone', value: '' },
  ],
  [
    { title: 'Team name', name: 'team_name', value: '' },
    { title: 'Coach name', name: 'club_coach_name', value: '' },
    { title: 'Coach email', name: 'club_coach_email', value: '' },
    { title: 'Coach phone', name: 'club_coach_phone', value: '' },
    { title: 'Uni#', name: 'uniform1', value: '' },
    { title: 'Pos 1', name: 'position1_abbrev', value: '' },
    { title: 'Pos 2', name: 'position2_abbrev', value: '' },
    { title: 'Team age', name: 'team_age', value: '' },
    { title: 'Team rank', name: 'team_rank', value: '' },
  ],
];

const physical = [
  [
    { title: 'Height', name: 'height', value: '', fn: AthleteHelpers.toInches },
    { title: 'Weight', name: 'weight', value: '' },
    { title: 'Reach', name: 'reach', value: '' },
  ],
  [
    { title: 'Approach', name: 'approach', value: '' },
    { title: 'Block', name: 'block', value: '' },
    { title: 'Handed', name: 'handed', value: '' },
  ],
];

const academic = [
  [
    { title: 'GPA', name: 'gpa', value: '' },
    { title: 'GPA scale', name: 'gpa_scale', value: '' },
    { title: 'PSAT', name: 'psat', value: '' },
    { title: 'SAT total', name: 'sat_total', value: '' },
    { title: 'ACT total', name: 'act', value: '' },
  ],
  [
    { title: 'School status', name: 'scholarship_status', value: '' },
    { title: 'College Attend.', name: 'college_attending', value: '' },
    { title: 'NCSA ID', name: 'ncsa_client_id', value: '' },
  ],
];

const parents = [
  [
    { title: 'Name', name: 'parent1_name', value: '' },
    { title: 'Email', name: 'parent1_email', value: '' },
    { title: 'Phone', name: 'parent1_phonec', value: '' },
  ],
  [
    { title: 'Name', name: 'parent2_name', value: '' },
    { title: 'Email', name: 'parent2_email', value: '' },
    { title: 'Phone', name: 'parent2_phonec', value: '' },
  ],
];
const highSchool = [
  [
    { title: 'School name', name: 'high_school', value: '' },
    { title: 'Address', name: 'high_school_address', value: '' },
    { title: 'City', name: 'high_school_city', value: '' },
    { title: 'State', name: 'high_school_state', value: '' },
    { title: 'ZIP', name: 'high_school_zip', value: '' },
  ],
  [
    { title: 'Uni#', name: 'hs_uniform_number', value: '' },
    { title: 'Position 1', name: 'hs_position_primary', value: '' },
    { title: 'Position 2', name: 'hs_position_secondary', value: '' },
    { title: 'Team', name: 'hs_team_level', value: '' },
  ],
];

export { general, clubTeam, physical, academic, parents, highSchool };
